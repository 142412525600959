import * as S from './layout';
import Star from './Star';
import { RatingType } from './types';
import { getStarsShape } from './utils';

interface Props {
  rating?: RatingType;
  className?: string;
}

const Rating = ({ rating, className }: Props) => {
  if (!rating) return null;

  const formattedAverageRating = rating.average.toFixed(1).replace('.', ',');

  return (
    <S.RatingContainer className={className}>
      <S.StarsContainer>
        {getStarsShape(rating.average).map((mode, index) => (
          // more performant than using a generated random key and the order won't change

          <Star key={`${mode}-${index}`} mode={mode} />
        ))}
      </S.StarsContainer>
      <p>{formattedAverageRating}</p>
      <p>({rating.nbRatings})</p>
    </S.RatingContainer>
  );
};

export default Rating;
