import { useRouter } from 'next/router';

import Dialog from '~/src/common/components/Dialog';
import type { IconNames } from '~/src/common/components/Icon';
import I18n from '~/src/common/services/I18n';
import Tracker from '~/src/common/services/Tracker';
import { usePostAuthMeQuery } from '~/src/screens/App/queries/hooks';

import { useMyProductsDeleteProductSku } from './use-my-products-delete-product-sku';
import { useMyProductsGetProductSkus } from './use-my-products-get-product-skus';

export const useMyProductsButton = (product: { id: string; sku: string; name: string }) => {
  const { push, route } = useRouter();
  const { data: user } = usePostAuthMeQuery();
  const { data: skus } = useMyProductsGetProductSkus();
  const { mutate: deleteSku } = useMyProductsDeleteProductSku();

  // Rien à faire si pas connecté ou si le produit n'est pas dans la liste
  if (user == null || !skus?.includes(product.sku)) return null;

  // Icône, label et handler en mode classique
  const normalIcon: IconNames = 'list-heart-normal';
  const normalLabel = I18n.t('my-products-screen.buttons.ordered');
  const normalHandler = () => {
    Tracker.sendEvent('my products informations modal viewed');
    Dialog.info({
      size: 'medium',
      title: I18n.t('my-products-screen.dialogs.cardinfo.title'),
      description: I18n.t('my-products-screen.dialogs.cardinfo.text'),
      cancelText: null,
      confirmText: I18n.t('my-products-screen.dialogs.cardinfo.ok'),
      onConfirm: () => void push('/mes-produits'),
    });
  };

  // Icône, label et handler en mode suppression
  const deleteIcon: IconNames = 'trash';
  const deleteLabel = I18n.t('my-products-screen.buttons.delete');
  const deleteHandler = () => {
    Tracker.sendEvent('delete products confirmation modal viewed');
    Dialog.info({
      size: 'medium',
      title: I18n.t('my-products-screen.dialogs.delete.title'),
      description: I18n.t('my-products-screen.dialogs.delete.text'),
      cancelText: I18n.t('my-products-screen.dialogs.delete.no'),
      confirmText: I18n.t('my-products-screen.dialogs.delete.yes'),
      onConfirm: () => {
        void deleteSku(
          { sku: product.sku },
          { onSuccess: () => Tracker.sendEvent('product deleted from my products') },
        );
      },
    });
  };

  // Selon la page, l'icône et l'action au click sont différents
  const isMyProductsPage = route === '/mes-produits';
  const [icon, label, handler] = isMyProductsPage
    ? [deleteIcon, deleteLabel, deleteHandler]
    : [normalIcon, normalLabel, normalHandler];

  // Le handler est wrap pour bloquer le bubbling de l'event
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    handler();
  };

  return { icon, label, handleClick };
};
