import React, { SyntheticEvent } from 'react';

import { useNiceModal } from '~/src/common/services/ModalsManager';
import { noop } from '~/src/common/utils/function';
import { DeliveryOrPickupStepsName } from '~/src/screens/DeliveryModal/types';

import I18n from '../../services/I18n';

import {
  DeliveryInfoText,
  LaterButton,
  DeliveryInfoButton,
  DeliveryInfoIcon,
  DeliveryInfoContainer,
  DeliveryInfoTitle,
} from './layout';

interface Props {
  hasAddress: boolean;
  isDelivery: boolean;
  onClose: (e: SyntheticEvent) => void;
  onOpenDeliveryModal?: () => void;
  onUpdateDelivery: () => void;
}

const DeliveryInfoPanel = ({
  onClose,
  hasAddress,
  isDelivery,
  onOpenDeliveryModal,
  onUpdateDelivery,
}: Props) => {
  const deliveryModal = useNiceModal('delivery-modal');
  const textKey = hasAddress ? 'slot' : 'address';

  const handleClick = () => {
    if (onOpenDeliveryModal) onOpenDeliveryModal();

    let initialStep: DeliveryOrPickupStepsName = 'PICK_ADDRESS';
    if (hasAddress) initialStep = isDelivery ? 'SELECT_DELIVERY_SLOT' : 'SELECT_PICKUP_SLOT';

    deliveryModal.show({ initialStep, onUpdateDelivery }).catch(noop);
  };

  const handleLaterClick = (e: SyntheticEvent) => {
    onClose(e);
  };

  return (
    <DeliveryInfoContainer>
      <DeliveryInfoTitle>{I18n.t(`delivery-info-panel.no-${textKey}-title`)}</DeliveryInfoTitle>
      <DeliveryInfoIcon name={hasAddress ? 'clock-normal' : 'Move-delivery'} />
      <DeliveryInfoText>{I18n.t(`delivery-info-panel.no-${textKey}-text`)}</DeliveryInfoText>
      <DeliveryInfoButton
        variant="secondary"
        aria-label={I18n.t(`delivery-info-panel.no-${textKey}-button-text`)}
        onClick={handleClick}>
        {I18n.t(`delivery-info-panel.no-${textKey}-button-text`)}
      </DeliveryInfoButton>
      <LaterButton onClick={handleLaterClick} variant="link">
        {I18n.t('delivery-info-panel.close-button')}
      </LaterButton>
    </DeliveryInfoContainer>
  );
};

export default DeliveryInfoPanel;
