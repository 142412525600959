import Tippy from '@tippyjs/react';
import styled from 'styled-components';

export const StyledTippy = styled(Tippy)`
  background: ${({ theme }) => theme.palette.common.WHITE};
  color: ${({ theme }) => theme.palette.text};
  box-shadow:
    0 0 20px 4px rgb(154 161 177 / 15%),
    0 4px 80px -8px rgb(36 40 47 / 25%),
    0 4px 4px -2px rgb(91 94 105 / 15%);
  background-color: ${({ theme }) => theme.palette.common.WHITE};

  .tippy-content {
    & > * {
      font-size: 12px;
      line-height: 16px;
    }
  }

  .tippy-arrow {
    color: ${({ theme }) => theme.palette.common.WHITE};
  }

  &[data-placement^='top'] {
    .tippy-arrow {
      border-top-color: ${({ theme }) => theme.palette.common.WHITE};
    }
  }

  &[data-placement^='bottom'] {
    .tippy-arrow {
      border-bottom-color: ${({ theme }) => theme.palette.common.WHITE};
    }
  }

  &[data-placement^='left'] {
    .tippy-arrow {
      border-left-color: ${({ theme }) => theme.palette.common.WHITE};
    }
  }

  &[data-placement^='right'] {
    .tippy-arrow {
      border-right-color: ${({ theme }) => theme.palette.common.WHITE};
    }
  }
`;
