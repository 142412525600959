import { useQueryClient } from '@tanstack/react-query';

import {
  getGetAccountProductsQueryKey as getProductListQueryKey,
  getGetAccountProductsSkuQueryKey as getProductSkusQueryKey,
  useDeleteAccountProductsSku as useDeleteAccountProductSkuOrval,
} from '~/src/queries/api-ecom/generated/api-ecom';

import { ProductList, ProductSkus } from '../types';

// Pour mettre à jour la liste catégorisé des produits de l'utilisateur
const optimisticallyUpdateProductList = (data: ProductList, sku: string): ProductList => {
  const categories = data.categories.flatMap(category => {
    // Supprime le produit de la catégorie, si présent
    const products = category.products.filter(p => p.sku !== sku);
    // Retourne la catégorie, uniquement si elle n'est pas vide
    return products.length > 0 ? [{ ...category, products }] : [];
  });
  return { categories };
};

// Pour mettre à jour la liste des SKU des produits de l'utilisateur
const optimisticallyUpdateProductSkus = (data: ProductSkus, sku: string): ProductSkus => {
  return data.filter(s => s !== sku);
};

// Pour la suppression d'un produit de la liste de l'utilisateur
export const useMyProductsDeleteProductSku = () => {
  const client = useQueryClient();
  return useDeleteAccountProductSkuOrval({
    mutation: {
      onMutate: data => {
        // Récupération des données actuellement présente dans le cache
        const prevList = client.getQueryData<ProductList>(getProductListQueryKey());
        const prevSkus = client.getQueryData<ProductSkus>(getProductSkusQueryKey());
        // Génération de la version optimiste des données
        const nextList = prevList && optimisticallyUpdateProductList(prevList, data.sku);
        const nextSkus = prevSkus && optimisticallyUpdateProductSkus(prevSkus, data.sku);
        // Mise à jour du cache manuellement
        client.setQueryData<ProductList>(getProductListQueryKey(), nextList);
        client.setQueryData<ProductSkus>(getProductSkusQueryKey(), nextSkus);
        // Contexte pour pouvoir revert
        return { prevList, prevSkus };
      },
      onError: (_err, _vars, ctx) => {
        // En cas d'erreur, on revert les modifications
        client.setQueryData<ProductList>(getProductListQueryKey(), ctx?.prevList);
        client.setQueryData<ProductSkus>(getProductSkusQueryKey(), ctx?.prevSkus);
      },
      onSettled: () => {
        // Dans tout les cas, on invalide les données pour les rafraichir
        void client.invalidateQueries(getProductListQueryKey());
        void client.invalidateQueries(getProductSkusQueryKey());
      },
    },
  });
};
