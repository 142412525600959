import React from 'react';

import FrenchFlag from '~/src/common/components/GridCard/ProductCard/assets/french-flag.svg';
import { HomeProduct } from '~/src/common/typings/product';

import FRENCH_LOCATIONS from './frenchLocations';

interface Props extends React.SVGProps<SVGSVGElement> {
  origin?: HomeProduct['origin'];
}

const isFrenchOrigin = (country: HomeProduct['origin']) =>
  country != null && FRENCH_LOCATIONS.includes(country.trim());

const OriginFlag = ({ origin, ...rest }: Props) => {
  return origin != null && isFrenchOrigin(origin) ? <FrenchFlag {...rest} /> : null;
};

export default OriginFlag;
