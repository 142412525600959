import styled from 'styled-components';

export const CardButtonContainer = styled.button`
  position: absolute;
  inset: 8px 8px auto auto;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;

  cursor: pointer;
  font-size: 16px;
  color: ${({ theme }) => theme.palette.common.PRIMARY};

  border: none;
  border-radius: 4px;
  background: ${({ theme }) => theme.palette.common.PROMOTION_LIGHT};
`;

export const FocusButtonContainer = styled.button`
  display: flex;
  width: fit-content;
  align-items: center;

  gap: 8px;
  margin: 0;
  padding: 8px;

  cursor: pointer;
  color: ${({ theme }) => theme.palette.common.PRIMARY};

  border: none;
  border-radius: 4px;
  background: ${({ theme }) => theme.palette.common.PROMOTION_LIGHT};

  [role='img'] {
    font-size: 24px;
  }

  label {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }

  > * {
    pointer-events: none;
  }
`;
