import EmptyStar from '~/src/common/assets/rating/empty-star.svg';
import FullStar from '~/src/common/assets/rating/full-star.svg';
import HalfStar from '~/src/common/assets/rating/half-star.svg';

import type { getStarsShape } from './utils';

interface StarProps {
  mode: ReturnType<typeof getStarsShape>[number];
  size?: number;
  color?: string;
}

const Star = ({ mode, size = 10, color = '#F0B323' }: StarProps) => {
  if (mode === 'full') {
    return (
      <FullStar
        stroke={color}
        fill={color}
        width={size}
        height={size}
        aria-label="full-star"
        role="img"
      />
    );
  }

  if (mode === 'empty') {
    return (
      <EmptyStar stroke={color} width={size} height={size} aria-label="empty-star" role="img" />
    );
  }

  return (
    <HalfStar
      fill={color}
      stroke={color}
      width={size}
      height={size}
      aria-label="half-star"
      role="img"
    />
  );
};

export default Star;
